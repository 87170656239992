const LocalStoragePlugin = {
  install(Vue) {
    Vue.prototype.$localStorage = {
      set(key, value, hours = 1) {
        const now = new Date();
        const item = {
          value: value,
          expiry: now.getTime() + hours * 60 * 60 * 1000, // 指定的小时数后过期
        };
        localStorage.setItem(key, JSON.stringify(item));
      },

      get(key) {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
          return null; // 未找到数据
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
          localStorage.removeItem(key); // 数据已过期，移除
          return null;
        }
        return item.value;
      },

      remove(key) {
        localStorage.removeItem(key);
      },

      clear() {
        localStorage.clear();
      },
    };
  },
};

export default LocalStoragePlugin;
