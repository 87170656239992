// 公用地址
// let web = "https://test5.czweb.com.cn/api/wechat/";
let web = "wechat/";

// 接口
let url = [
  [`${web}index`, "0 获取用户信息"],
  [`${web}site/index`, "1 获取配置信息"],
  [`${web}user/upload`, "2 更新用户信息"],
  [`${web}oss`, "3 上传图片"],
  [`${web}send`, "4 发送验证码"], //
  [`${web}credentials`, "5 提交信息"], //
  [`${web}manage`, "6 绑定管理员"], //getuser
  [`${web}getuser`, "7 审批获取用户信息"],
  [`${web}examine`, "8 审批结果"],
  [`${web}generate`, "9 生成证书"],
];

// 抛出
export { web, url };

export function shouldAllowNavigation(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null; // 未找到数据
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key); // 数据已过期，移除
    return null;
  }
  return item.value;
}
