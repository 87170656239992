<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      open: null,
    };
  },
  created() {
    this.getUrl()
  },
  methods: {
    getUrl() {

      let info = this.getUrlCode(); //是否存在code
      let path = 'home';
      if (info.to) {
        path = unescape(info.to).slice(1)
      }

      if (info.state) {
        this.$localStorage.set('openid', info.openid);
        this.$request(0, { openid: info.openid })
          .then((res) => {
            this.$localStorage.set('userInfo', res.info);
            this.$router.push({ path: '/' + this.$router.history.current.query.state });
          })
          .catch((message) => {
            console.log('res', message);
          })
      } else {
        let url = "https://certificate.szea.cn";
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxed308a7ba9ec3950&redirect_uri=" + url + "&response_type=code&scope=snsapi_userinfo&state=" + path + "#wechat_redirect";
      }
    },

    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },

    // isWeiXin() {
    //   var ua = window.navigator.userAgent.toLowerCase();
    //   if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
};
</script>
