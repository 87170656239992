import Vue from "vue";
import VueRouter from "vue-router";
import { shouldAllowNavigation } from "../utils/request";

import Info from "../views/info.vue"; // 登陆
import Examine from "../views/examine.vue"; // 审核专用
import Home from "../views/Home.vue"; // 首页

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Info",
    component: Info,
    meta: { title: "用户登录" },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { title: "选手注册" },
  },
  {
    path: "/examine",
    name: "Examine",
    component: Examine,
    meta: { title: "基本信息" },
  },
  {
    path: "/curriculum",
    name: "Curriculum",
    component: () => import("../views/Curriculum.vue"),
    meta: { title: "获取电子证书" },
  },
  {
    path: "/manage",
    name: "manage",
    component: () => import("../views/manage.vue"),
    meta: { title: "管理员绑定" },
  },
  {
    path: "/approve",
    name: "approve",
    component: () => import("../views/approve.vue"),
    meta: { title: "申请审批" },
  },
  {
    path: "/certificateView",
    name: "certificateView",
    component: () => import("../views/certificateView.vue"),
    meta: { title: "审批结果" },
  },
  {
    path: "/404",
    component: () => import("../views/404.vue"),
    meta: { title: "404" },
  },
  {
    path: "/info",
    component: () => import("../views/info.vue"),
    meta: { title: "关于我们" },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const createRouter = () =>
  new VueRouter({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes,
  });

const router = createRouter();

router.beforeEach((to, from, next) => {
  // 在每次路由导航前执行的全局前置守卫
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // 检查用户是否已登录，您可以在 Home 组件中实现这个逻辑
  const userIsLoggedIn = shouldAllowNavigation("openid");

  if (to.path !== "/" && !userIsLoggedIn) {
    // 如果用户未登录且不在 /home 路由，重定向到登录页面
    next(`/?to=${to.fullPath}`);
  } else {
    next();
  }

  // next();
});

export default router;
