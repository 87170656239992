// 封装请求：get post
// 面向对象es6 promise  等等。。。

// 请求地址
import axios from "axios";
import { Toast } from "vant";

import { url } from "./request.js";

// POST请求
let Posting = function (urling, shopdata, message = "加载中...") {
  console.log(shopdata);
  let __data = this.$encrypt(
    shopdata,
    "JrAg7qMi2wTFJnq5ssjJEo9xtEhPtSxX",
    "8Z2wMy7amABbqsGV"
  );
  const toast = Toast.loading({
    message,
    forbidClick: true,
    position: "top",
    duration: 0,
  });
  return new Promise((resolve, reject) => {
    axios
      .post(
        url[urling][0],
        { data: __data },
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? localStorage.getItem("token")
              : "123",
          },
        }
      )
      .then(function (response) {
        let { code, msg, data } = response.data;
        if (code == 1) {
          toast.clear();
          resolve(data);
        } else {
          toast.clear();
          Toast(msg);
          reject(msg);
        }
      })
      .catch(function (e) {
        toast.clear();
        reject(e);
      })
  });
};

export { Posting };
